<template>
  <div class="border-all px-6 py-1 ma-4 rounded">
    <v-row v-if="!data" class="my-0">
      <v-col cols="12" v-for="i in 6" :key="i">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"><v-skeleton-loader type="list-item"></v-skeleton-loader></v-col>
          <v-col cols="9"><v-skeleton-loader type="list-item"></v-skeleton-loader></v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-else class="my-0">
      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">"ANTEC" MMC-yə qədərki iş stajı</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ data.totalPastworkExperienceDetails ? data.totalPastworkExperienceDetails : '—' }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"
            ><span class="subtitle-2 gray--text">"ANTEC" MMC-də olan iş stajı</span></v-col
          >
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ data.currentWorkExperienceDetails ? data.currentWorkExperienceDetails : '—' }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"><span class="subtitle-2 gray--text">Ümumi iş stajı</span></v-col>
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ data.totalWorkExperience ? data.totalWorkExperience : '—' }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3 border-bottom">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"><span class="subtitle-2 gray--text">Əmək haqqı gross(AZN)</span></v-col>
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ data.grossSalary ? data.grossSalary : '—' }}
            </span></v-col
          >
        </v-row>
      </v-col>

      <v-col cols="12" class="py-3">
        <v-row class="row-reset no-gutters">
          <v-col cols="3"><span class="subtitle-2 gray--text">Əmək Haqqı net(AZN)</span></v-col>
          <v-col cols="9"
            ><span class="body-2 gray--text text--darken-3"
              >{{ data.netSalary ? data.netSalary : '—' }}
            </span></v-col
          >
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import API from '@/services'

export default {
  data() {
    return {
      data: null
    }
  },
  methods: {
    async checkID() {
      if (this.$route.params.id && this.$route.params.id > 0) {
        this.data = await API.fetchHREmployeeIternshipAndSalary(this.$route.params.id)
      }
    }
  },
  created() {
    this.checkID()
  }
}
</script>
